@import '../../mixins/all.scss';
.about {
  width: 100vw;
  background: $white-color;
  margin-top: -2px;
  position: relative;
  z-index: 2;

  &_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;

    padding-top: 15px;

    .title_container {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      margin-bottom: $v-title-margin-m;

      .line {
        width: 100px;
        margin-right: 15px;
      }
    }

    p {
      width: 100%;
      max-width: 260px;
      text-align: right;
      line-height: 1.8rem;
    }
  }
}
@include mobile-m {
  .about {
    &_container {
      padding-top: 25px;

      p {
        max-width: 350px;
      }
    }
  }
}
@include mobile-l {
  .about {
    &_container {
      p {
        max-width: 400px;
      }
    }
  }
}
@include tablet {
  .about {
    &_container {
      p {
        max-width: 520px;
      }
    }
  }
}
@include laptop {
  .about {
    &_container {
      p {
        max-width: 560px;
      }
    }
  }
}
@include laptop-l {
  .about {
    &_container {
      .title_container .line {
        width: 220px;
      }

      p {
        max-width: 600px;
      }
    }
  }
}
