@import '../mixins/all.scss';

.nav_menu {
  width: 100vw;
  position: fixed;
  z-index: 999;
  top: 100px;
  right: 0;
  padding: 0 15px;
  pointer-events: none;

  .link {
    pointer-events: all;

    display: block;
    width: 100%;
    text-decoration: none;

    background: rgba(255, 255, 255, 0.95);
    border-radius: 15px;
    margin-bottom: 15px;
    margin-left: 100vw;
    transition: margin 0.3s ease-in-out;

    -webkit-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.15);
    box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.15);

    @for $i from 1 through 6 {
      &:nth-child(#{$i}) {
        transition: margin 0.2s #{$i * 0.05}s ease-in-out;
      }
    }

    &_inner {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;

      padding: 12px 16px;

      &_icon {
        margin-right: 15px;
        height: 25px;
        width: 25px;

        img {
          width: 100%;
          height: 100%;
        }
      }

      p {
        font-size: 1.5rem;
        color: black;
        text-transform: uppercase;
      }
    }
  }

  &__open {
    .link {
      margin-left: 0px;
    }
  }
}

@include mobile-m {
  .nav_menu {
    width: 100vw;
    top: 105px;
    padding: 0 20px;

    .link {
      margin-bottom: 20px;

      &_inner {
        padding: 14px 18px;

        &_icon {
          margin-right: 18px;
          height: 25px;
          width: 25px;
        }
      }
    }
  }
}
@include mobile-l {
  .nav_menu {
    width: 320px;
    top: 110px;
    padding: 0 15px;

    .link {
      margin-bottom: 15px;

      &_inner {
        padding: 14px 18px;

        &_icon {
          margin-right: 18px;
          height: 25px;
          width: 25px;
        }
      }
    }
  }
}
@include tablet {
  .nav_menu {
    position: static;
    width: 400px;
    padding: 0;

    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    flex-wrap: wrap;

    .link {
      width: auto;
      margin-bottom: 0px;
      padding: 4px 10px;

      background: transparent;
      border-radius: 0px;
      margin-left: 0px;

      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;

      transition: opacity 0.2s ease-in-out !important;

      &_inner {
        padding: 0;

        &_icon {
          display: none;
        }

        p {
          font-size: 1.65rem;
          color: $white-color;
          text-shadow: 0.2rem 0.2rem 0.4rem rgba(0, 0, 0, 0.7);
          -webkit-text-shadow: 0.2rem 0.2rem 0.4rem rgba(0, 0, 0, 0.7);
          -moz-text-shadow: 0.2rem 0.2rem 0.4rem rgba(0, 0, 0, 0.7);

          transition: all 0.2s ease-in-out;
        }
      }

      &__dark .link_inner p {
        text-shadow: 0.2rem 0.2rem 0.4rem rgba(0, 0, 0, 0);
        -webkit-text-shadow: 0.2rem 0.2rem 0.4rem rgba(0, 0, 0, 0);
        -moz-text-shadow: 0.2rem 0.2rem 0.4rem rgba(0, 0, 0, 0);

        color: $gray-color;
      }

      &:hover {
        opacity: 0.6;
      }
    }
  }
}
@include laptop {
  .nav_menu {
    flex-grow: 1;
    width: auto;

    .link {
      padding: 4px 10px;

      &_inner {
        padding: 0;

        p {
          font-size: 1.65rem;
        }
      }

      &:nth-last-child(1) {
        padding-right: 0px;
      }
    }
  }
}
@include laptop-l {
  .nav_menu {
    .link {
      padding: 4px 10px;

      &_inner {
        padding: 0;

        p {
          font-size: 1.65rem;
        }
      }
    }
  }
}
