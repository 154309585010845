@import '../../mixins/all.scss';
.vision {
  width: 100vw;
  margin-top: -2px;
  position: relative;
  z-index: 3;

  background-position: center top;
  background-size: cover;
  background-repeat: no-repeat;
  @include background-2x('../../images/425/3', 'png');

  padding-top: 120px;

  &_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    .title_container {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: $v-title-margin-m;

      .line {
        margin-left: 15px;
        width: 60px;
      }
    }

    .content_block {
      width: 100%;
      margin-bottom: $v-margin-m;

      h3 {
        margin-bottom: 10px;
      }

      .values {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        margin-top: $v-title-margin-m;

        .value {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-around;

          background: $white-color;
          width: calc(100vw - 2 * #{$h-padding-m});
          height: calc((100vw - 2 * #{$h-padding-m}) * 0.68);
          max-width: 280px;
          min-height: 230px;

          max-width: 280px;
          margin-bottom: 30px;
          padding: 20px;
          text-align: center;

          box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
          -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
          -moz-box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);

          &_icon {
            width: 40px;
            height: 40px;
          }

          p {
            width: auto;
          }
        }
      }
    }
  }
}
