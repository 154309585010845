@mixin mobile-m {
  @media only screen and (min-width: 425px) {
    @content;
  }
}

@mixin mobile-l {
  @media only screen and (min-width: 576px) {
    @content;
  }
}

@mixin tablet {
  @media only screen and (min-width: 768px) {
    @content;
  }
}

@mixin laptop {
  @media only screen and (min-width: 1024px) {
    @content;
  }
}

@mixin laptop-l {
  @media only screen and (min-width: 1440px) {
    @content;
  }
}

@mixin background-2x($path, $ext: 'png', $important: '') {
  $at1x_path: '#{$path}.#{$ext}';
  $at2x_path: '#{$path}@2x.#{$ext}';

  background-image: url('#{$at1x_path}') #{$important};

  @media all and (-webkit-min-device-pixel-ratio: 2),
    all and (-o-min-device-pixel-ratio: 2),
    all and (min--moz-device-pixel-ratio: 2),
    all and (min-device-pixel-ratio: 2) {
    background-image: url('#{$at2x_path}') #{$important};
  }
}

@mixin sprite-2x($path, $xpos, $ypos, $size) {
  $ext: 'png';
  $at1x_path: '#{$path}.#{$ext}';
  $at2x_path: '#{$path}@2x.#{$ext}';

  background: url('#{$at1x_path}') $xpos $ypos;
  background-size: $size;

  @media all and (-webkit-min-device-pixel-ratio: 2),
    all and (-o-min-device-pixel-ratio: 2),
    all and (min--moz-device-pixel-ratio: 2),
    all and (min-device-pixel-ratio: 2) {
    background: url('#{$at2x_path}') $xpos $ypos;
    background-size: $size;
  }
}
