@import './mixins/all.scss';

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Open Sans', sans-serif;
  font-weight: 300;
  font-size: 1.4rem;
  color: $gray-color;
}

html {
  font-size: 62.5%;

  @include tablet {
    font-size: 68.75%;
  }

  @include laptop {
    font-size: 75%;
  }

  @include laptop-l {
    font-size: 88%;
  }
}

html,
body,
#root {
  width: 100%;
  height: 100%;
}

body {
  overflow-x: hidden;
}

/* INPUT FIXES */
input {
  border-radius: 0px;
}

textarea:focus,
input:focus {
  outline: none;
}

.svg-bow-container {
  svg {
    width: 100%;
    display: none;

    &:nth-child(1) {
      display: inline-block;
    }

    @include tablet {
      &:nth-child(1) {
        display: none;
      }

      &:nth-child(2) {
        display: inline-block;
      }
    }

    @include laptop {
      &:nth-child(2) {
        display: none;
      }

      &:nth-child(3) {
        display: inline-block;
      }
    }
  }
}

.line {
  margin-top: 4px;
  background-color: $gray-color;
  height: 1px;
}

.container-fixed-width {
  width: 100%;
  max-width: 1400px;
  margin-left: auto;
  margin-right: auto;
  padding-left: $h-padding-m;
  padding-right: $h-padding-m;
}
