@import './mixins/all.scss';

/* COLORS */
.color-white {
  color: $white-color;
}

.color-dark {
  color: $gray-color;
}

.color-black {
  color: black;
}

/* WIEGHTS */
.font-weight-thin {
  font-weight: 300;
}

.font-weight-normal {
  font-weight: 400;
}

.font-weight-semi-bold {
  font-weight: 600;
}

/* SIZES */
.font-17 {
  font-size: 1.7rem;
  line-height: 1.8rem;
}

.font-15 {
  font-size: 1.5rem;
  line-height: 1.6rem;
}

/* SPECIAL */
.font-shadow {
  text-shadow: 0.2rem 0.2rem 0.4rem rgba(0, 0, 0, 0.5);
  -webkit-text-shadow: 0.2rem 0.2rem 0.4rem rgba(0, 0, 0, 0.5);
  -moz-text-shadow: 0.2rem 0.2rem 0.4rem rgba(0, 0, 0, 0.5);
}

.font-italic {
  font-style: italic;
}

h2 {
  font-size: 1.9rem !important;
}

h3 {
  font-weight: 600 !important;
  font-size: 1.5rem;
  line-height: 3rem;
}

/* REST */
.nav_link,
.nav_link_no_scroll,
.slogan_text {
  color: white;
  font-weight: 600;
}

.nav_link,
.nav_link_no_scroll {
  text-decoration: none;
  text-transform: uppercase;
  text-shadow: 0.2rem 0.2rem 0.4rem rgba(0, 0, 0, 0.7);
  -webkit-text-shadow: 0.2rem 0.2rem 0.4rem rgba(0, 0, 0, 0.7);
  -moz-text-shadow: 0.2rem 0.2rem 0.4rem rgba(0, 0, 0, 0.7);
}

.slogan_text {
  text-shadow: 0.2rem 0.2rem 0.4rem rgba(0, 0, 0, 0.5);
  -webkit-text-shadow: 0.2rem 0.2rem 0.4rem rgba(0, 0, 0, 0.5);
  -moz-text-shadow: 0.2rem 0.2rem 0.4rem rgba(0, 0, 0, 0.5);
}

.keyword,
h1 {
  color: #3d3d3c;
  font-weight: normal;
}

.main,
.warning_light {
  font-weight: 300;
  color: #3d3d3c;
}

.warning_light {
  font-size: 2rem;
  line-height: 2.3rem;
}

.main_bold {
  font-weight: 600;
  color: #3d3d3c;
}

.main_cursive {
  font-style: italic;
  font-weight: 300;
  color: #3d3d3c;
}
