/* COLORS */
$white-color: #fff;
$gray-color: #3d3d3c;
$accent-color: #f5f8f9;

/* PADDINGS */
$h-nav-padding-ms: 15px;
$h-nav-padding-mm: 20px;
$h-nav-padding-ml: 25px;
$h-nav-padding-t: 30px;
$h-nav-padding-l: 40px;

$h-padding-m: 30px;
$h-padding-t: 50px;
$h-padding-l: 60px;

/* MARGINS */
$v-title-margin-m: 15px;

$v-margin-m: 50px;
$v-margin-t: 15px;
$v-margin-l: 15px;
