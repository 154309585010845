@import '../../mixins/all.scss';

.spotlight {
  position: relative;
  z-index: 2;
  overflow: hidden;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    margin-top: 50px;
    transform: translateY(-50%);
    transition: transform 0.5s ease-in-out;

    .block {
      width: 100vw;
      text-align: center;
      padding: 0 25px;

      .title {
        margin-bottom: 6px;
        font-size: 1.8rem;
        font-weight: 400;
      }

      p {
        font-size: 1.5rem;
        line-height: 1.5rem;
      }
    }
  }

  .bullets {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    .dot {
      cursor: pointer;

      margin: 18px 3px;
      width: 6px;
      height: 6px;
      border-radius: 50%;

      box-shadow: 0 0 10px rgba(0, 0, 0, 0.7);
      -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.7);
      -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.7);
      -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.7);
      -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.7);
      background-color: rgba(255, 255, 255, 0.5);

      transition: background-color 0.5s ease-in-out;

      &__active {
        background-color: $white-color;
      }
    }
  }
}
@include tablet {
  .spotlight {
    .container {
      .block {
        padding: 0 25px;

        .title {
          margin-bottom: 12px;
          font-size: 2rem;
        }

        p {
          font-size: 1.7rem;
          line-height: 1.7rem;
        }
      }
    }

    .bullets {
      .dot {
        margin: 22px 5px;
        width: 8px;
        height: 8px;
      }
    }
  }
}
