@import './mixins/all.scss';

.webp {
  .headerImage__loaded {
    @include background-2x('../../images/header-mobile', 'webp', '!important');
  }

  @include tablet {
    .headerImage__loaded {
      @include background-2x('../../images/header', 'webp', '!important');
    }
  }
}

.no-webp {
  .headerImage__loaded {
    @include background-2x('../../images/header-mobile', 'jpg', '!important');
  }

  @include tablet {
    .headerImage__loaded {
      @include background-2x('../../images/header', 'jpg', '!important');
    }
  }
}
