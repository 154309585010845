@import '../mixins/all.scss';

.header {
  height: 100vh;
  position: relative;
  overflow: hidden;
  min-height: 300px;

  &_loader {
    display: none;
  }

  &_background {
    z-index: 0;
    top: 0;
    left: 0;
    position: absolute;
    height: 100%;
    width: 100%;

    -webkit-transition: background-image 0.2s ease-in-out;
    transition: background-image 0.2s ease-in-out;

    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    @include header-mobile;
  }

  &_top {
    background-position: center top;
    background-size: cover;
    background-repeat: no-repeat;
    @include background-2x('../../images/425/1', 'png');

    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    width: 100%;
    height: 85px;

    &__content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      height: 100%;
      width: 100%;
      padding: 0 $h-nav-padding-ms;
    }
  }

  &_spotlight {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 40%;
    transform: translateY(-50%);
  }

  &_bottom {
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 123px;

    @include background-2x('../../images/425/2', 'png');
    background-position: center bottom;
    background-size: cover;
    background-repeat: no-repeat;
  }
}
@include mobile-m {
  .header {
    &_top {
      height: 91px;
      @include background-2x('../../images/576/1', 'png');

      &__content {
        padding: 0 $h-nav-padding-mm;
      }
    }

    &_bottom {
      height: 128px;
      @include background-2x('../../images/576/2', 'png');
    }
  }
}
@include mobile-l {
  .header {
    &_top {
      height: 111px;
      @include background-2x('../../images/768/1', 'png');

      &__content {
        padding: 0 $h-nav-padding-ml;
      }
    }

    &_bottom {
      height: 132px;
      @include background-2x('../../images/768/2', 'png');
    }
  }
}
@include tablet {
  .header {
    &_top {
      height: 121px;
      @include background-2x('../../images/1024/1', 'png');

      &__content {
        padding: 0 $h-nav-padding-t;

        &__dark {
          background: rgb(255, 255, 255);
          background: -moz-linear-gradient(
            0deg,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 0.7) 30%,
            rgba(255, 255, 255, 1) 100%
          );
          background: -webkit-linear-gradient(
            0deg,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 0.7) 30%,
            rgba(255, 255, 255, 1) 100%
          );
          background: linear-gradient(
            0deg,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 0.7) 30%,
            rgba(255, 255, 255, 1) 100%
          );
          filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#ffffff",GradientType=1);
        }
      }
    }

    &_bottom {
      height: 144px;
      @include background-2x('../../images/1024/2', 'png');
    }
  }
}
@include laptop {
  .header {
    &_top {
      height: 125px;
      @include background-2x('../../images/1440/1', 'png');

      &__content {
        padding: 0 $h-nav-padding-l;
      }
    }

    &_bottom {
      height: 202px;
      @include background-2x('../../images/1440/2', 'png');
    }
  }
}
@include laptop-l {
  .header {
    &_top {
      height: 130px;
      @include background-2x('../../images/3840/1', 'png');

      &__content {
        padding: 0 $h-nav-padding-l;
      }
    }

    &_bottom {
      height: 265px;
      @include background-2x('../../images/3840/2', 'png');
    }
  }
}
